import React, { useEffect,  } from 'react';
import Cookies from 'js-cookie'
import { v4 as uuid } from 'uuid'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, CSSReset, useToast, Text, Icon, Button, Box, Heading, useDisclosure } from '@chakra-ui/core'

// Load components
import ELearning from './components/ELearning/ELearning'
import LandingPage from './components/LandingPage/LandingPage';
import PharmacyTraining from './components/PharmaLearning/PharmaLearning'
import Animations from './components/Animations/Animations'
import Modal from './components/Modal/Modal'
import CookiePolicy from './components/Footer/Content/CookiePolicy';
import MOA from './components/MOA/MOA'
import Webinar from './components/Webinar/Webinar'
import WebinarQA from './components/Webinar/WebinarQA';
import WebinarPresentation from './components/Webinar/WebinarPresentation';

// Load theme
import customTheme from './theme'
import './font/stylesheet.css'
import { headingText, normalText, submitBtn } from './style/styleFormat';

const CookieToast = ({ openPolicy }) => {
  const toast = useToast()
  
  useEffect(() => {
    function renderToast() {
      const id = uuid()
      toast({
        position: "bottom-left",
        duration: null,
        isClosable: true,
        render: ({ onClose }) => (
          <Box backgroundColor="#fff" padding="4">
            <Box display="flex" justifyContent="space-between" marginBottom="10px">
                <Heading {...headingText} >Cookie Policy</Heading>
                <Icon name="close" onClick={onClose} cursor="pointer" />
            </Box>
            <Text {...normalText} marginBottom="20px">
              We use cookies to ensure that we give you the best experience on our website. <Text as="span" onClick={openPolicy} textDecoration="underline" cursor="pointer">Click here</Text> for more information
            </Text>
            <Button {...submitBtn} onClick={() => { 
              Cookies.set('hana-cookies', {accepted: true, date: new Date(), id}, {expires: 365})
               onClose() 
              }} width="25%">
              OK
            </Button>
          </Box>
        ),
        status: "success",
       
      })
    }

    if (!Cookies.getJSON('hana-cookies')) {
       return  renderToast()
    }
  }, [toast, openPolicy])

  return toast
}



function App() {
  const { isOpen, onClose, onOpen } = useDisclosure()
  
  return (
   <ThemeProvider theme={customTheme}>
      <CSSReset />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/training"  component={ELearning}/>
        <Route path="/teamtraining" component={PharmacyTraining} />
        <Route path="/animations" component={Animations} />
        <Route path="/moa" component={MOA} />
        <Route exact path="/webinar" component={Webinar} />
        <Route exact path="/webinar/qa" component={WebinarQA} />
        <Route exact path="/webinar/presentation" component={WebinarPresentation} />
        <Redirect to="/" />
      </Switch>
      <CookieToast openPolicy={onOpen} />
      <Modal isOpen={isOpen} onClose={onClose} size="lg-custom" title="Cookies Policy">
        <CookiePolicy />
      </Modal>
   </ThemeProvider>
      
  );
}

export default App;
